/* Основной стиль страницы */
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f2f5f9;
  color: #333;
  box-sizing: border-box;
}

/* Верхняя панель */
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
  height: 60px; /* Фиксированная высота */
}

/* Доход в час */
.income-hourly {
  font-size: 16px;
  font-weight: bold;
  color: #4caf50;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 45%;
}

/* Баланс монет */
.coin-balance {
  font-size: 16px;
  font-weight: bold;
  color: #ff9800;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 45%;
  text-align: right;
}

/* Общие стили для текста в TopBar */
.top-bar div {
  flex: 1;
  text-align: center;
  padding: 0 10px;
}

/* Прогресс-бар */
.progress-bar-container {
  width: 80%;
  margin: 100px auto 20px auto;
  background-color: #ddd;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  height: 20px;
  background: linear-gradient(90deg, #4caf50, #8bc34a);
  transition: width 0.5s ease;
}

/* Общий доход */
.income-display {
  font-size: 22px;
  font-weight: bold;
  color: #388e3c;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  width: 80%;
  margin: 20px auto;
}

/* Контейнер дашборда */
.dashboard-container {
  text-align: center;
  padding: 20px;
  margin-top: 80px;
}

/* Элементы с количеством */
.item-container {
  width: 80%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Ряд элемента с множителем */
.item-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 18px;
}

/* Множитель (X) */
.multiplier {
  font-weight: bold;
  font-size: 20px;
  color: #4a90e2;
}

/* Нижняя панель навигации */
.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  z-index: 1000;
  border-top: 1px solid #ddd;
}

/* Кнопки нижнего меню */
.bottom-nav-button {
  background: none;
  border: none;
  font-size: 20px;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.2s ease;
}

.bottom-nav-button:hover,
.bottom-nav-button.active {
  color: #4a90e2;
  transform: scale(1.1);
}

.bottom-nav-button span {
  font-size: 12px;
  margin-top: 5px;
}

/* 📊 Страница статистики */
.stats-container {
  text-align: center;
  padding: 20px;
  margin-top: 80px;
  margin-bottom: 80px;
}

.stats-container h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.stats-card {
  background-color: #ffffff;
  padding: 20px;
  margin: 10px auto;
  width: 80%;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.stats-card:hover {
  transform: scale(1.03);
}

.stats-card h3 {
  font-size: 18px;
  color: #4a90e2;
}

.stats-card p {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

/* ⚙️ Страница настроек */
.settings-container {
  text-align: center;
  padding: 20px;
  margin-top: 80px;
}

.settings-container h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.settings-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 15px 20px;
  margin: 10px auto;
  width: 80%;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.toggle-button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: #388e3c;
}

/* 📈 Вкладка улучшений */
.upgrades-container {
  text-align: center;
  padding: 20px;
  margin-top: 80px;
  margin-bottom: 80px;
}

.upgrades-container h2 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #4a90e2;
}

/* Кнопки улучшений */
.upgrade-button {
  width: 80%;
  max-width: 300px;
  padding: 15px 20px;
  margin: 15px auto;
  background-color: #4a90e2;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: block;
}

.upgrade-button:hover {
  background-color: #357ab7;
  transform: scale(1.05);
}

.upgrade-button:active {
  transform: scale(0.95);
}
